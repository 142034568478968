<script setup lang="ts">
const props = defineProps({
  error: Object
})
const title = computed(() => {
  switch(props.error.statusCode) {
    case 404:
      return 'Page not found'
    case 500:
      return 'Internal server error'
    default:
      return 'Error'
  }
})

const description = computed(() => {
  switch(props.error.statusCode) {
    case 404:
      return 'Sorry, we couldn’t find the page you’re looking for.'
    case 500:
      return 'Sorry, something went wrong.'
    default:
      return 'Sorry, something went wrong.'
  }
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <NuxtLayout name="error">
    <p class="text-base font-semibold text-red-600">{{error.statusCode}}</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-gray-100">{{ title }}</h1>
    <p class="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">{{ description }}</p>
  </NuxtLayout>
</template>